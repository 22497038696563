import { DeviceStatusInfo } from '@desquare/models';
import { TopicLogic } from 'libs/core/services/src/lib/device-data/mqtt.topic.logic';

/**
 * Send MQTT message/command to device
 *
 * @param msg
 * @returns
 */
export const mqttMessageToDeviceStatusInfo = (msg: string) => {
  switch (msg) {
    case TopicLogic.MSG_PAYLOADS.STATUS_ONLINE:
      return DeviceStatusInfo.Online;
    default:
      return DeviceStatusInfo.Offline;
  }
};
